/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

// Material checkbox styles
.form-check-input[type="checkbox"],
label.btn input[type="checkbox"] {
  // Text Label Style
  + label {
    position: relative;
    padding-left: $checkbox-label-pl;
    cursor: pointer;
    display: inline-block;
    height: $checkbox-label-height;
    line-height: $checkbox-label-line-height;
    user-select: none;
  }

  // Checkbox feature
  + label:before,
   &:not(.filled-in) + label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: $checkbox-label-before-after-width;
    height: $checkbox-label-before-after-height;
    z-index: 0;
    border: $checkbox-label-before-after-border-width solid $checkbox-label-before-after-border-color;
    border-radius: $checkbox-label-before-after-border-radius;
    margin-top: $checkbox-label-before-after-mt;
    transition: $checkbox-label-before-after-transition;
  }

  &:not(.filled-in) + label:after {
    border: 0;
    transform: scale(0);
  }

  &:not(:checked):disabled + label:before {
    border: none;
    background-color: $input-disabled-solid-color;
  }

  &:checked {
    + label:before {
      top: $checkbox-checked-label-before-top;
      left: $checkbox-checked-label-before-left;
      width: $checkbox-checked-label-before-width;
      height: $checkbox-checked-label-before-height;
      border-top: $checkbox-label-before-after-border-width solid transparent;
      border-left: $checkbox-label-before-after-border-width solid transparent;
      border-right: $checkbox-label-before-after-border-width solid $radio-fill-color;
      border-bottom: $checkbox-label-before-after-border-width solid $radio-fill-color;
      transform: $checkbox-checked-label-before-transform;
      backface-visibility: hidden;
      transform-origin: $checkbox-checked-label-before-transform-origin;
    }

    &:disabled + label:before {
      border-right: $checkbox-label-before-after-border-width solid $input-disabled-solid-color;
      border-bottom: $checkbox-label-before-after-border-width solid $input-disabled-solid-color;
    }
  }

  // Indeterminate checkbox
  &:indeterminate {
    + label:before {
      top: $checkbox-indeterminate-label-before-top;
      left: $checkbox-indeterminate-label-before-left;
      width: $checkbox-indeterminate-label-before-width;
      height: $checkbox-indeterminate-label-before-height;
      border-top: none;
      border-left: none;
      border-right: $checkbox-label-before-after-border-width solid $radio-fill-color;
      border-bottom: none;
      transform: $checkbox-indeterminate-label-before-transform;
      backface-visibility: hidden;
      transform-origin: $checkbox-checked-label-before-transform-origin;
    }

    &:disabled + label:before {
      border-right: $checkbox-label-before-after-border-width solid $input-disabled-color;
      background-color: transparent;
    }
  }

  // Filled in Style
  &.filled-in {
    + label:after {
      border-radius: $checkbox-filled-in-border-radius;
    }

    + label:before,
    + label:after {
      content: '';
      left: 0;
      position: absolute;
      /* .1s delay is for check animation */
      transition: $checkbox-filled-in-transition-border, $checkbox-filled-in-transition-background-color, $checkbox-filled-in-transition-width, $checkbox-filled-in-transition-height, $checkbox-filled-in-transition-top, $checkbox-filled-in-transition-left;
      z-index: 1;
    }

    // Unchecked style
    &:not(:checked) + label:before {
      width: 0;
      height: 0;
      border: $checkbox-filled-in-unchecked-label-before-border-width solid transparent;
      left: $checkbox-filled-in-unchecked-label-before-left;
      top: $checkbox-filled-in-unchecked-label-before-top;
      transform: rotateZ(37deg);
      transform-origin: $checkbox-checked-label-before-transform-origin;
    }

    &:not(:checked) + label:after {
      height: $checkbox-filled-in-unchecked-label-after-height;
      width: $checkbox-filled-in-unchecked-label-after-width;
      background-color: transparent;
      border: $checkbox-label-before-after-border-width solid $radio-empty-color;
      top: 0;
      z-index: 0;
    }

    // Checked style
    &:checked {
      + label:before {
        top: 0;
        left: $checkbox-filled-in-checked-label-before-left;
        width: $checkbox-filled-in-checked-label-before-width;
        height: $checkbox-filled-in-checked-label-before-height;
        border-top: $checkbox-label-before-after-border-width solid transparent;
        border-left: $checkbox-label-before-after-border-width solid transparent;
        border-right: $checkbox-label-before-after-border-width solid $white-base;
        border-bottom: $checkbox-label-before-after-border-width solid $white-base;
        transform: $checkbox-filled-in-unchecked-label-before-transform;
        transform-origin: $checkbox-checked-label-before-transform-origin;
      }

      + label:after {
        top: 0;
        width: $checkbox-filled-in-unchecked-label-after-height;
        height: $checkbox-filled-in-unchecked-label-after-width;
        border: $checkbox-label-before-after-border-width solid $secondary-color;
        background-color: $secondary-color;
        z-index: 0;
      }
    }

    &.filled-in-danger {
      &:checked + label:after {
        background-color: $red-base;
        border-color: $red-base;
      }
    }
  }

  // Disabled style
  &:disabled {
    &:not(:checked) {
      + label:before {
        background-color: $input-disabled-solid-color;
        border-color: $input-disabled-solid-color;
      }
      + label:after {
        border-color: $input-disabled-solid-color;
        background-color: $input-disabled-solid-color;
      }
    }
    &:checked {
      + label:before {
        background-color: transparent;
      }
      + label:after {
        background-color: $input-disabled-solid-color;
        border-color: $input-disabled-solid-color;
      }
    }
  }
}
