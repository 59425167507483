//     __
//    / /_  ____  ____ ___  ___
//   / __ \/ __ \/ __ `__ \/ _ \
//  / / / / /_/ / / / / / /  __/
// /_/ /_/\____/_/ /_/ /_/\___/
// -----------------------------
.section-home {
  background: url(../assets/img/home-bg.jpg) no-repeat;
  background-size: cover;
  margin-top: -106px;
  height: 100vh;
  positionr: relaive;

  .content-block {
    color: #fff;
    padding: 20em 0 0;
    display: block;

    h1 {
      @include fontSetting($font-one, 500, 58px, 66px);
      white-space: nowrap;
    }

    p {
      @include fontSetting($font-one, 100, 24px, 30px);
    }
  }
}

//                           __
//    _________  ___  ____ _/ /_____  _____
//   / ___/ __ \/ _ \/ __ `/ //_/ _ \/ ___/
//  (__  ) /_/ /  __/ /_/ / ,< /  __/ /
// /____/ .___/\___/\__,_/_/|_|\___/_/
//     /_/
// -----------------------------------
.section-one {
  background: url(../assets/img/speaker-casey-cheshire.png) no-repeat right bottom;
  background-size: _rem(530px);
  height: calc(100vh - 106px);

  .bottom-btn {
    position: relative;
    margin: 470px auto 0;
    display: inline-block;
  }

  .content-block {
    padding: 70px 0 50px;

    h1 {
      @include fontSetting($font-one, 500, 48px, 48px);
      color: $color-body-font-dark;
      text-shadow: none;
    }
  }
}

//                          __
//   _________  ____ ______/ /_
//  / ___/ __ \/ __ `/ ___/ __ \
// / /__/ /_/ / /_/ / /__/ / / /
// \___/\____/\__,_/\___/_/ /_/
// -----------------------------

.section-two {
  background-image: url(../assets/img/coach-bg.jpg);
  background-size: cover;
  height: calc(100vh - 106px);

  .content-block {
    padding: 50px 0 0;
    color: #fff;
  }

  h1 {
    color: #fff;
    @include fontSetting($font-one, 500, 53px, 66px);
    white-space: nowrap;
    padding: 0 0 20px 0;
  }

  .cta-box {
    background-color: rgba(000, 000, 000, .5);
    padding: 20px;
    color: #fff;
  }

}

//     ____              __
//    / __ )____  ____  / /__
//   / __  / __ \/ __ \/ //_/
//  / /_/ / /_/ / /_/ / ,<
// /_____/\____/\____/_/|_|
// ---------------------------

.section-three {
  background-image: url(../assets/img/book-bg.png);
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    padding: 300px 0 20px;
    text-shadow: none;
    @include fontSetting($font-one, 500, 48px, 66px);
    color: $color-body-font-dark;
  }
}

.section-three-content {
  color: $color-body-font-dark;

  h2 {
    @include fontSetting($font-one, 500, 28px, 34px);
  }

  .cta-box {
    background-color: rgba(000, 000, 000, .6);
    padding: 20px;
  }

  .praiser {
    @include fontSetting($font-one, 400, 16px, 22px);
  }
}




//  _       __           __      _       ___ __  __       __  ___
// | |     / /___  _____/ /__   | |     / (_) /_/ /_     /  |/  /__
// | | /| / / __ \/ ___/ //_/   | | /| / / / __/ __ \   / /|_/ / _ \
// | |/ |/ / /_/ / /  / ,<      | |/ |/ / / /_/ / / /  / /  / /  __/
// |__/|__/\____/_/  /_/|_|     |__/|__/_/\__/_/ /_/  /_/  /_/\___/
// -----------------------------------------------------------------
.section-four {
  background-image: url(../assets/img/work-with-me-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    padding: 330px 0 20px;
    @include fontSetting($font-one, 500, 48px, 66px);
    color: #fff;
  }

  .navbar {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.section-four-content {
  .content-block {
    // padding: 120px 0 200px;
    color: $color-body-font-dark;

    h2 {
      @include fontSetting($font-one, 500, 22px, 34px);
      margin-bottom: 1em;
      color: $color-body-font-dark;
    }

    .cta-box {
      background-color: rgba(000, 000, 000, .6);
      padding: 20px;
    }
  }
}



//                      __                __
//     ____  ____  ____/ /________ ______/ /_
//    / __ \/ __ \/ __  / ___/ __ `/ ___/ __/
//   / /_/ / /_/ / /_/ / /__/ /_/ (__  ) /_
//  / .___/\____/\__,_/\___/\__,_/____/\__/
// /_/
// ---------------------------

.section-five {
  background-image: url(../assets/img/podcast.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    padding: 300px 0 100px;
    text-shadow: none;
    @include fontSetting($font-one, 500, 48px, 66px);
    color: $color-body-font-dark;
  }
}

.section-five-content {
  color: $color-body-font-dark;

  h2 {
    @include fontSetting($font-one, 500, 28px, 34px);
  }

  .cta-box {
    background-color: rgba(000, 000, 000, .6);
    padding: 20px;
  }

  .praiser {
    @include fontSetting($font-one, 400, 16px, 22px);
  }
  .podcast-source-list{
    a{
      @include fontSetting($font-one, 400, 38px, 24px);            
      margin:0 _rem(10px) 0 0;
      font-size:_rem(36px);
      &:hover{
        color:#94d5f0;
      }
    }
  }
}



form.form {
  text-align: center;
  margin: 0 auto 4em;

  input[type="text"],
  textarea {
    width: 100%;
    color: #99b7c5;
    padding: 1em;
    border: 1px solid #94d5f0;
    margin: 1em auto;
  }

  input:focus,
  textarea:focus {
    border: 1px solid #383942;
    color: #383942;
    outline: 0;
  }

  ::placeholder {
    color: #99b7c5;
  }

  input[type=submit] {
    text-align: center;
    margin: .5em auto;
    color: #383942;
    outline: 0;
    cursor: pointer;
    @include fontSetting($font-one, 500, 24px, 32px);
    text-transform: uppercase;
    display: inline-block;
    color: #ffffff;
    padding: 15px 25px;
    background: #ffba00;
    webkit-box-shadow: 4px 4px 0px 0px rgba(233, 141, 8, 1);
    -moz-box-shadow: 4px 4px 0px 0px rgba(233, 141, 8, 1);
    box-shadow: 4px 4px 0px 0px rgba(233, 141, 8, 1);
    text-shadow: 2px 1px 1px #ffa119;
    -webkit-transition: all $animation_fast $easing;
    -moz-transition: all $animation_fast $easing;
    -ms-transition: all $animation_fast $easing;
    -o-transition: all $animation_fast $easing;
    transition: all $animation_fast $easing;
    border: none;

    &:hover {
      color: #fff;
      webkit-box-shadow: 0px 0px 0px 0px rgba(233, 141, 8, .1);
      -moz-box-shadow: 0px 0px 0px 0px rgba(233, 141, 8, .1);
      box-shadow: 0px 0px 0px 0px rgba(233, 141, 8, .1);
    }
  }
}

form.news-letter {
  color: #383942;

  input[type="text"] {
    color: #383942;
    padding: 0 15px !important;
    border: none;
    float: left;
    outline: 0 !important;
  }

  input[type="text"]::placeholder {
    color: #99b7c5;
  }

  input[type=submit] {
    cursor: pointer;
    float: right;
    text-align: center;
    @include fontSetting($font-one, 300, 18px, 22px);
    text-transform: uppercase;
    display: inline-block;
    color: #ffffff;
    padding: 6px 0;
    border: none;
    outline: 0;
    background: #ffba00;
    outline: 0;
    text-shadow: 1px 1px 0px #ffa119;
    -webkit-transition: all $animation_fast $easing;
    -moz-transition: all $animation_fast $easing;
    -ms-transition: all $animation_fast $easing;
    -o-transition: all $animation_fast $easing;
    transition: all $animation_fast $easing;

    &:hover {
      color: #fff;
    }

  }
}


.privacy-policy button[type="button"] {
  @include fontSetting($font-one, 500, 18px, 32px);
  padding: 10px 16px;
}


a.privacy-policy-link {
  color: #383942;
}
