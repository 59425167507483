
// CSS Reset

::-moz-selection{
	background-color: transparentize(#ffba00,0.2);
	color: $color_white;
	text-shadow: none;
}
::selection{
	background-color: transparentize(#ffba00,0.2);
	color: $color_white;
	text-shadow: none;
}

// Defaults
html{
	background: $color_white;
	
	font-family: $font-one;
	font-weight: $font_normal;
	line-height: $content_lh;
	color: $color_black;
	letter-spacing: $content_ls;
	
	text-rendering: auto;
	
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body{
	min-width: $width_min;
	font-family:$font-one;
	color:$color-off-black;
	font-weight:300;
}

h1,h2,h3,h4,h5,h6{
	font-family: $font-one;
	line-height: $title_lh;
	font-weight: $font_normal;
	letter-spacing: $title_ls;
}
h1,h2{font-size: $fs_l}
h3,h4,h5,h6{font-size: _rem(26px)}
h5,h6{font-weight: $font_normal}

sub,sup{
	color: inherit;
	font-size: 55.55555556%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}
	sup{top: -0.8em}
	sub{bottom: 0}
	
small{font-size: 60%}
large{font-size: 140%}
strong,dt{font-weight: $font_bold}
a{color: inherit}
cite,dfn,em,i,q,var{font-style: italic}
del,s,strike{text-decoration: line-through}
u{text-decoration: underline}
img{	
	display: block;
	width: 100%;
	height: auto;
}
mark,ins{
	background-color: transparent;
	display: inline-block;
	position: relative;
	
	color: inherit;
	margin: 0 0.5em;
	
	z-index: 2;
	
	&:before{
		content: '';
		
		background-color: rgba(yellow,0.3);
		position: absolute;
		left: -0.5em;
		top: -0.15em;
		width: 100%;
		height: 100%;
		
		padding: 0.15em 0.5em;
		border-radius: 1px;
		box-sizing: content-box;
		
		z-index: -1;
	}
}
code{
	font-family: sans-serif;
	
	border: 1px solid;
	padding: 0.15em 0.5em;
}
pre{
	display: block;
	position: relative;
	
	font-family: sans-serif;
	
	padding: $spacer_m;
	border: 1px solid;
	
	overflow: auto;
	
	box-shadow: inset 0 0 3em -1em $color_primary;

}
q{
	&:before,&:after{font-family: $font-two}
	&:before{content: '\201C'}
	&:after{content: '\201D'}
}
blockquote{
	display: block;
	position: relative;
	text-align: center;
	
	&:before,&:after{
		display: block;
		
		width: 2em;
		height: 2em;
		
		font-size: 4rem;
		line-height: 1;
	}
	
	&:before{
		content: '\201C';
		
		position: relative;
		
		color: $color_primary;
		
		border-radius: 1em;
		margin: 0 auto 1em;
		padding-top: 0.75em;
		
		box-sizing: border-box;
		
		z-index: 2;
	}
	
	&:after{
		content: '';
		
		background: currentcolor;
		position: absolute;
		left: 50%;
		top: 0;
		
		border-radius: 1em;
		margin-left: -1em;
		
		z-index: 1;
	}
	
	p{
		font-style: italic;
		
		&:before,&:after{font-family: $font-one}
		&:before{content: '\201C'}
		&:after{content: '\201D'}
	}
}

// Fixes

// Body margin fix
body{
	padding-top: 1px;
	margin-top: -1px;
}

// Form fixes
// input::-webkit-input-placeholder{color: inherit; font-style: inherit}
// input::-moz-placeholder{color: inherit; font-style: inherit}
// input:-moz-placeholder{color: inherit; font-style: inherit}
// input:-ms-input-placeholder{color: inherit; font-style: inherit}
// input::placeholder{color: inherit; font-style: inherit}
// input,textarea,select,input[type=range]{
// 	border-radius: 0;
// 	border: none;
// 	box-shadow: none;
// 	outline: none;
// 	-moz-appearance:none;
// 	-webkit-appearance: none;
// 	appearance: none;
	
// 	&:focus{outline: none}
// 	&::-ms-clear{display: none}
// 	&::-moz-focus-inner{border: 0;padding: 0;}
// 	&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus{transition: all 216000s 216000s ease}
// }
// input::-moz-focus-inner{border:0 !important; padding:0 !important}