/* ==========================================================================
   $BASE-DATE-PICKER
   ========================================================================== */

/**
 * The picker box.
 */

.picker__box {
  padding: 0;
  border-radius: $datepicker-box-padding;
  overflow: hidden;
  /**
   * The header containing the month and year stuff.
   */
  .picker__header {
    text-align: center;
    position: relative;
    margin-bottom: $datepicker-header-mb;
    select {
      display: inline-block !important;
    }
    .picker__date-display {
      text-align: center;
      background-color: $datepicker-date-bg;
      @extend .white-text;
      padding-bottom: $datepicker-date-display-pb;
      font-weight: $datepicker-date-display-font-weight;
      margin-bottom: $datepicker-date-display-mb;
      .picker__weekday-display {
        background-color: $datepicker-weekday-bg;
        padding: $datepicker-weekday-display-padding;
        font-weight: $datepicker-weekday-display-font-weight;
        letter-spacing: $datepicker-weekday-display-letter-spacing;
        font-size: $datepicker-weekday-display-font-size;
        margin-bottom: $datepicker-weekday-display-mb;
      }
      .picker__month-display {
        text-transform: uppercase;
        font-size: $datepicker-month-display-font-size;
      }
      .picker__day-display {
        font-size: $datepicker-day-display-font-size;
        font-weight: $datepicker-day-display-font-weight;
      }
      .picker__year-display {
        font-size: $datepicker-year-display-font-size;
        color: $datepicker-year;
      }
    }
    /**
       * The month and year labels.
       */
    .picker__month,
    .picker__year {
      display: inline-block;
      margin-left: $datepicker-year-ml;
      margin-right: $datepicker-year-mr;
    }
    /**
       * The month and year selectors.
       */
    .picker__select--month,
    .picker__select--year {
      height: $datepicker-select-month-height;
      padding: 0;
      margin-left: $datepicker-select-month-ml;
      margin-right: $datepicker-select-month-mr;
      display: inline-block;
      &:focus {
        border-color: $datepicker-focus;
      }
    }
    .picker__select--year {
      width: $datepicker-select-year;
    }
    // Modified
    .picker__select--month.browser-default {
      display: inline;
      @extend .white;
      width: $datepicker-select-month-browser-width;
    }
    .picker__select--year.browser-default {
      display: inline;
      @extend .white;
      width: $datepicker-select-year-browser-width;
    }
    /**
       * The month navigation buttons.
       */
       .picker__nav--prev,
       .picker__nav--next {
         position: absolute;
         padding: $datepicker-nav-prev-next-py $datepicker-nav-prev-next-px;
         width: $datepicker-nav-prev-next-width;
         height: $datepicker-nav-prev-next-height;
         box-sizing: content-box;
         bottom: 0;
         &:hover {
           cursor: pointer;
           color: $black-base;
           background: $datepicker-selected-outfocus;
         }
         &:before {
           content: " ";
           border-top: $datepicker-nav-prev-next-bt solid transparent;
           border-bottom: $datepicker-nav-prev-next-bb solid transparent;
           border-right: $datepicker-nav-prev-next-br solid $datepicker-nav-prev-next-br-color;
           width: 0;
           height: 0;
           display: block;
           margin: 0 auto;
         }
       }
       .picker__nav--prev {
         left: $datepicker-nav-prev-left;
         padding-right: $datepicker-nav-prev-pr;
       }
       .picker__nav--next {
         right: $datepicker-nav-next-right;
         padding-left: $datepicker-nav-next-pf;
         &:before {
           border-right: 0;
           border-left: $datepicker-nav-next-border-left solid $datepicker-nav-prev-next-br-color;
         }
       }
       .picker__nav--disabled,
       .picker__nav--disabled:hover,
       .picker__nav--disabled:before,
       .picker__nav--disabled:before:hover {
         cursor: default;
         background: none;
         border-right-color: $grey-lighten-4;
         border-left-color: $grey-lighten-4;
       }

     }

  /**
   * The calendar table of dates
   */
  .picker__table {
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    font-size: $datepicker-table-font-size;
    width: $datepicker-table-width;
    margin-top: $datepicker-table-mt;
    margin-bottom: $datepicker-table-mb;
    th, td {
      text-align: center;
    }
    td {
      margin: 0;
      padding: 0;
    }
    /**
       * The weekday labels
       */
    .picker__weekday {
      width: $datepicker-table-weekday-width;
      font-size: $datepicker-table-weekday-font-size;
      padding-bottom: $datepicker-table-weekday-padding-bottom;
      color: $datepicker-color-mdb;
      font-weight:$datepicker-table-weekday-font-weight;
      /* Increase the spacing a tad */
      @media (min-height: $pickerweekday-padb-breakpoint) {
        padding-bottom: $datepicker-table-weekday-padding-bottom;
      }
    }
    /**
       * The days on the calendar
       */
    .picker__day--today {
      position: relative;
      letter-spacing: $datepicker-table-day-today-letter-spacing;
      padding: $datepicker-table-day-today-py 0;
      font-weight: $datepicker-table-day-today-font-weight;
      border: $datepicker-table-day-today-border solid transparent;
    }
    .picker__day.picker__day--today {
      color: $datepicker-selected;
    }
    .picker__day--disabled{
      &:before {
        border-top-color: #aaaaaa;
      }
    }
    .picker__day--infocus{
      color: $datepicker-table-day-infocus-color;
      letter-spacing: $datepicker-table-day-infocus-letter-spacing;
      padding: $datepicker-table-day-infocus-py 0;
      font-weight: $datepicker-table-day-infocus-font-weight;
      border: $datepicker-table-day-infocus-color transparent;
      &:hover {
        cursor: pointer;
        color: $black;
        font-weight: $datepicker-table-day-infocus-hover-font-weight;
      }
    }
    .picker__day--outfocus {
      display: none;
      padding: $datepicker-table-day-outfocus-py 0;
      @extend .white-text;
      &:hover {
        cursor: pointer;
        color: $datepicker-table-day-outfocus-hover-color;
        font-weight: $datepicker-table-day-outfocus-hover-font-weight;
      }
    }
    .picker__day--highlighted:hover,
    .picker--focused .picker__day--highlighted {
      cursor: pointer;
    }
    .picker__day--selected,
    .picker__day--selected:hover,
    .picker--focused {
      //  Circle background
      border-radius: $datepicker-table-day-selected-hover-border-radius;
      transform: $datepicker-table-day-selected-hover-transform;
      background-color: $datepicker-selected;
      &.picker__day--outfocus {
        background-color: $datepicker-selected-outfocus;
      }
      @extend .white-text;
      box-shadow: $z-depth-1-half;
    }
    .picker__day--disabled,
    .picker__day--disabled:hover,
    .picker--focused {
      background: $grey-lighten-4;
      border-color: $grey-lighten-4;
      color: $datepicker-table-day-outfocus-hover-color;
      cursor: default;
    }
    .picker__day--highlighted.picker__day--disabled,
    .picker__day--highlighted.picker__day--disabled:hover {
      background: $datepicker-table-day-higlighted-color;
    }

  }

  /**
   * The footer containing the "today", "clear", and "close" buttons.
   */
  .picker__footer {
    text-align: right;
    padding: $datepicker-footer-py $datepicker-footer-px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .picker__button--today,
    .picker__button--clear,
    .picker__button--close {
      border: $datepicker-footer-today-clear-button-border solid $white;
      background: $white;
      font-size: $datepicker-footer-today-clear-button-font-size;
      padding: $datepicker-footer-today-clear-button-padding;
      font-weight: $datepicker-footer-today-clear-button-font-weight;
      width: $datepicker-footer-today-clear-button-width;
      display: inline-block;
      vertical-align: bottom;
      text-transform: uppercase;
      &:hover {
        cursor: pointer;
        color: $black;
        background: $datepicker-footer-today-clear-button-hover-color;
        border-bottom-color: $datepicker-footer-today-clear-button-hover-color;
      }
      &:focus {
        background: $datepicker-footer-today-clear-button-hover-color;
        border-color: $datepicker-focus;
        outline: none;
      }
      &:before {
        position: relative;
        display: inline-block;
        height: 0;
      }
    }
    .picker__button--today,
    .picker__button--clear{
      &:before {
        content: " ";
        margin-right: $datepicker-footer-today-clear-button-before-mr;
      }
    }
    .picker__button--today{
      &:before {
        top: $datepicker-footer-today-button-before-top;
        width: 0;
        border-top: $datepicker-footer-today-button-before-bt solid $datepicker-footer-today-button-before-bt-color;
        border-left: $datepicker-footer-today-button-before-bl solid transparent;
      }
    }
    .picker__button--clear{
      &:before {
        top: $datepicker-footer-clear-button-before-top;
        width: $datepicker-footer-clear-button-before-width;
        border-top: $datepicker-footer-clear-button-before-bl solid $datepicker-footer-clear-button-before-bt-color;
      }
    }
    .picker__button--close{
      &:before {
        content: "\D7";
        top: $datepicker-footer-close-button-before-top;
        vertical-align: top;
        font-size: $datepicker-footer-close-button-before-font-size;
        margin-right: $datepicker-footer-close-button-before-mr;
        color: $datepicker-footer-close-button-before-color;
      }
    }
    .picker__button--today[disabled],
    .picker__button--today[disabled]:hover {
      background: $grey-lighten-4;
      border-color: $grey-lighten-4;
      color: $datepicker-table-day-outfocus-hover-color;
      cursor: default;
    }
    .picker__button--today[disabled]:before {
      border-top-color: #aaaaaa;
    }
  }

}

/* ==========================================================================
   CUSTOM MATERIALIZE STYLES
   ========================================================================== */

.picker__calendar-container {
  padding: $datepicker-calender-padding;
  thead {
    border: none;
  }
}
