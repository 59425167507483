// FORMS PRO
.form-header {
  box-shadow: $z-depth-1-half;
  color: $form-header-color;
  text-align: center;
  margin-top: $form-header-mt;
  margin-bottom: $form-header-mb;
  padding: $form-header-padding;
  border-radius: $form-header-border-radius;
}
