//center block
%center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


%imgSetFull {
  width: 100%;  
  height:100vh;
}

.iframe-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    overflow: hidden;
    float: left;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, .4);
    }

    /* Make the iframe keep an aspect ratio, and
    position it in the middle of its parent wrapper*/
    iframe {
      width: 100vw;
      height: 56.25vw;
      /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 130vh;
      min-width: 250.77vh;
      /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }